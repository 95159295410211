import React from "react";
import { FormValidator } from "./FormValidator";
import { useTranslation } from "react-i18next";
import { TFunction } from "i18next";

interface IContext {
  children: React.ReactNode;
}

const FormContext = React.createContext(null as any);

const FIELD_IS_REQUIRED = "required";
interface IFieldInput {
  value: string;
  error: string;
  silentError: string;
}
const initialField = (t: TFunction<"formErrors">): IFieldInput => ({
  value: "",
  error: "",
  silentError: t(FIELD_IS_REQUIRED)
});

interface IConditions {
  value: boolean;
  error: string;
  silentError: string;
}
const initialConditions = (t: TFunction<"formErrors">): IConditions => ({
  value: false,
  error: "",
  silentError: t(FIELD_IS_REQUIRED)
});

interface ICaptcha {
  value: null | string;
  error: string;
  silentError: string;
}

const initialCaptcha = (t: TFunction<"formErrors">): ICaptcha => ({
  value: null,
  error: "",
  silentError: t(FIELD_IS_REQUIRED)
});

export const FormProvider = ({ children }: IContext) => {
  const { t } = useTranslation("formErrors");

  const [email, setEmail] = React.useState<IFieldInput>(initialField(t));
  const [position, setPosition] = React.useState<IFieldInput>(initialField(t));
  const [company, setCompany] = React.useState<IFieldInput>(initialField(t));
  const [website, setWebsite] = React.useState<IFieldInput>(initialField(t));
  const [phone, setPhone] = React.useState<IFieldInput>(initialField(t));
  const [conditions, setConditions] = React.useState<IConditions>(initialConditions(t));
  const [captcha, setCaptcha] = React.useState<ICaptcha>(initialCaptcha(t));

  const captchaRef = React.useRef(null as any);

  const handleEmail = (input: string) => {
    const validationError = new FormValidator(input, t).required().string().email().validate();
    setEmail((prev) => ({ value: input, error: !validationError ? "" : prev.error, silentError: validationError }));
  };
  const handlePosition = (input: string) => {
    const validationError = new FormValidator(input, t).required().string().validate();
    setPosition((prev) => ({ value: input, error: !validationError ? "" : prev.error, silentError: validationError }));
  };
  const handleCompany = (input: string) => {
    const validationError = new FormValidator(input, t).required().string().validate();
    setCompany((prev) => ({ value: input, error: !validationError ? "" : prev.error, silentError: validationError }));
  };
  const handleWebsite = (input: string) => {
    const validationError = new FormValidator(input, t).required().string().url().validate();
    setWebsite((prev) => ({ value: input, error: !validationError ? "" : prev.error, silentError: validationError }));
  };
  const handlePhone = (input: string) => {
    const validationError = new FormValidator(input, t).required().string().validate();
    setPhone((prev) => ({ value: input, error: !validationError ? "" : prev.error, silentError: validationError }));
  };
  const resetEmail = () => setEmail(initialField(t));
  const resetPosition = () => setPosition(initialField(t));
  const resetCompany = () => setCompany(initialField(t));
  const resetWebsite = () => setWebsite(initialField(t));
  const resetPhone = () => setPhone(initialField(t));
  const showEmailError = () => setEmail((prev) => ({ ...prev, error: prev.silentError }));
  const showPositionError = () => setPosition((prev) => ({ ...prev, error: prev.silentError }));
  const showCompanyError = () => setCompany((prev) => ({ ...prev, error: prev.silentError }));
  const showWebsiteError = () => setWebsite((prev) => ({ ...prev, error: prev.silentError }));
  const showPhoneError = () => setPhone((prev) => ({ ...prev, error: prev.silentError }));

  const toggleConditions = () =>
    setConditions((prev) => ({
      value: !prev.value,
      error: !prev.value === true ? "" : prev.error,
      silentError: !prev.value === false ? FIELD_IS_REQUIRED : ""
    }));
  const resetConditions = () => setConditions(initialConditions(t));
  const showConditionsError = () => setConditions((prev) => ({ ...prev, error: prev.silentError }));

  const handleCaptcha = (token: string | null) =>
    setCaptcha((prev) => ({
      value: token,
      error: !!token ? "" : prev.error,
      silentError: token === null ? FIELD_IS_REQUIRED : ""
    }));
  const resetCaptcha = () => {
    setCaptcha(initialCaptcha(t));
    if (captchaRef.current) {
      captchaRef.current.reset();
    }
  };
  const showCaptchaError = () => setCaptcha((prev) => ({ ...prev, error: prev.silentError }));

  const showAllErrors = () => {
    showEmailError();
    showPositionError();
    showCompanyError();
    showWebsiteError();
    showPhoneError();
    showConditionsError();
    showCaptchaError();
  };

  const resetAllFields = () => {
    resetEmail();
    resetPosition();
    resetCompany();
    resetWebsite();
    resetPhone();
    resetConditions();
    resetCaptcha();
  };

  return (
    <FormContext.Provider
      value={{
        email,
        position,
        company,
        website,
        phone,
        conditions,
        captcha,
        handleEmail,
        handlePosition,
        handleCompany,
        handleWebsite,
        handlePhone,
        toggleConditions,
        handleCaptcha,
        showAllErrors,
        resetAllFields,
        captchaRef
      }}
    >
      {children}
    </FormContext.Provider>
  );
};

export const useFormContext = () => React.useContext(FormContext);

import { MAILING_API_KEY, MAILING_SERVICE_TIMEOUT, MAILING_SERVICE_URL } from "@config/config";


const SERVICE_NAME_IN_DB = "g2nContactEmail";
const NEWSLETTER_NAME = "go2nft";

export const contactUs = async (userEmail: string, position: string, nameCompany: string, websiteName: string, phoneNumber: string,  captchaToken: string,) => {
  const method = "POST";
  const url = `${MAILING_SERVICE_URL}/${SERVICE_NAME_IN_DB}/contact`;
  const body = { email: userEmail, position: position, company: nameCompany, website: websiteName, phone:phoneNumber };

  return await sendEmail(url, method, body, captchaToken);
};

export const subscribeToNewsletter = async (email: string) => {
  const method = "POST";
  const url = `${MAILING_SERVICE_URL}/newsletters/${NEWSLETTER_NAME}/subscribe`;
  const body = {
    email,
    name: "go2nft",
    lang: "en"
  };
  return await sendEmail(url, method, body);
};

const sendEmail = async (url: string, method: string, body: Record<string, any>, captchaToken?: string) => {
  const trueBody = JSON.stringify({
    ...body
  });

  const controller = new AbortController();
  const timeoutId = setTimeout(() => controller.abort(), MAILING_SERVICE_TIMEOUT);

  const req = async () => {
    const response = await fetch(url, {
      method: method,
      body: trueBody,
      signal: controller.signal,
      headers: {
        "X-API-KEY": MAILING_API_KEY,
        recaptcha: captchaToken || "",
        "Content-Type": "application/json"
      }
    });

    clearTimeout(timeoutId);
    return response;
  };
  return await req();
};

import {
  TELEGRAM_URL,
  INSTAGRAM_URL,
  FACEBOOK_URL,
  YOUTUBE_URL,
  MEDIUM_URL,
  TWITTER_URL,
  LOGO_GO2NFT
} from "./socials";
export const footerSocials = [
  {
    logo: "/images/footer/telegram.svg",
    url: TELEGRAM_URL
  },
  {
    logo: "/images/footer/instagram.svg",
    url: INSTAGRAM_URL
  },
  {
    logo: "/images/footer/facebook.svg",
    url: FACEBOOK_URL
  },
  {
    logo: "/images/footer/youtube.svg",
    url: YOUTUBE_URL
  },
  {
    logo: "/images/footer/medium.svg",
    url: MEDIUM_URL
  },
  {
    logo: "/images/footer/twitter.svg",
    url: TWITTER_URL
  }
];

export const footerLogo = [
  {
    url: LOGO_GO2NFT
  }
];

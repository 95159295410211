import { paths } from "@data/paths";
import React from "react";
import { useLocation } from "react-router-dom";
import { writeSessionStorage } from "src/utils/storageManager";

interface IContext {
  children: React.ReactNode;
}

export const LAST_CASE_STUDY_PATH_KEY = "Last_case_study_path";

const TabsContext = React.createContext(null as any);

export const TabsProvider = ({ children }: IContext) => {
  const location = useLocation();

  const caseStudyPathsOnly = Object.values(paths)
    .filter((pathObject) => pathObject.appearInTabs)
    .map((path) => {
      return {
        ...path,
        active: location.pathname === path.path
      };
    });

  const activeObj = caseStudyPathsOnly.filter((x) => x.active)[0];

  return <TabsContext.Provider value={{ caseStudyPathsOnly, activeObj }}>{children}</TabsContext.Provider>;
};

export const useTabs = () => React.useContext(TabsContext);

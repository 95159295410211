import useAsyncGetSessionStored from "@hooks/useAsyncGetSessionStored";
import { EntertainmentCategories } from "@pages/Entertainment/categories";
import go2nftAPI from "src/integrations/backend/go2nft.api";
import { SetDTO } from "src/integrations/backend/go2nft.dto";

export interface Props {
  category: EntertainmentCategories;
}

export interface ProductsReturn {
  isLoading: boolean;
  array: SetDTO[] | null;
}

const PRODUCTS_KEY = "products_key";

const useProductsList = ({ category }: Props): ProductsReturn => {
  const products = useAsyncGetSessionStored<SetDTO[]>(go2nftAPI.getSetsTHROWS, `${category}-${PRODUCTS_KEY}`, category);

  return { isLoading: products.isLoading, array: products.value };
};

export default useProductsList;

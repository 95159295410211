import { paths } from "@data/paths";
import { extrasSubPage, productSlug } from "./slugs";

export const musicPagePath = paths.music.path;
export const musicProductPagePath = `${musicPagePath}/:${productSlug}`;
export const musicExtrasPagePath = `${musicProductPagePath}/${extrasSubPage}`;

export const gamingPagePath = paths.gaming.path;
export const gamingProductPagePath = `${gamingPagePath}/:${productSlug}`;
export const gamingExtrasPagePath = `${gamingProductPagePath}/${extrasSubPage}`;

import React from "react";
import { useTranslation } from "react-i18next";

import { FormValidator } from "@pages/Business/context/FormValidator";
import { subscribeToNewsletter } from "@services/EmailService";

import Input from "@components/dataEntry/Input/Input";

import "./Newsletter.scss";

const Newsletter = () => {
  const [inputValue, setInputValue] = React.useState("");
  const [inputError, setInputError] = React.useState("");
  const [subscribedMessage, setSubscribedMessage] = React.useState<null | boolean>(null);
  const [responseError, setResponseError] = React.useState("");

  const { t } = useTranslation("layout");
  const { t: errorsT } = useTranslation("formErrors");

  React.useEffect(() => {
    if (subscribedMessage !== null) {
      setTimeout(() => {
        setSubscribedMessage(null);
      }, 5000);
    }
  }, [subscribedMessage]);

  const clearForm = () => {
    setInputValue("");
    setInputError("");
  };

  const handleSubscribe = async () => {
    if (!(inputError.trim().length > 0)) {
      try {
        const response = await subscribeToNewsletter(inputValue);
        if (response.ok) {
          setSubscribedMessage(true);
          setResponseError("");
        } else {
          const responseError = (await response.json()).message;
          setSubscribedMessage(false);
          setResponseError(responseError);
        }
      } catch (e) {
        setSubscribedMessage(false);
        setResponseError("Couldn't send request");
      } finally {
        clearForm();
      }
    }
  };

  const handleSetInput = (input: string) => {
    const error = new FormValidator(input, errorsT).required().email().validate();
    setInputValue(input);
    setInputError(error);
  };

  return (
    <div className="newsletter">
      <h3 className="white-4 medium mb-2">{t("footer.newsletter.title")}</h3>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleSubscribe();
        }}
      >
        <Input
          label={""}
          value={inputValue}
          onChange={handleSetInput}
          error={""}
          autoFocus={false}
          required={false}
          fullSize={true}
          inputRequiremenets={""}
          variant="secondary"
          type="email"
          placeholder={t("footer.newsletter.input.placeholder")}
          children={
            <button type="submit">
              <img src="/images/arrow-right-white.svg" width={40} height={40} alt="Send button" />
            </button>
          }
        />
        {subscribedMessage === true && (
          <p className="p3 response success">{t("footer.newsletter.subscribedMessage.success")}</p>
        )}
        {subscribedMessage === false && <p className="p3 response danger">{responseError}</p>}
      </form>
    </div>
  );
};

export default Newsletter;

import { useCallback, useEffect, useState } from "react";
import { useWindowDimensions } from "./useWindowDimensions";

interface Size {
  width: number;
  height: number;
}

function useElementSize<T extends HTMLElement = HTMLDivElement>(): [(node: T | null) => void, Size] {
  const [ref, setRef] = useState<T | null>(null);
  const [size, setSize] = useState<Size>({
    width: 0,
    height: 0
  });

  const { width: viewportWidth, height: viewportHeight } = useWindowDimensions();

  const handleSize = useCallback(() => {
    setSize({
      width: ref?.offsetWidth || 0,
      height: ref?.offsetHeight || 0
    });
  }, [ref?.offsetHeight, ref?.offsetWidth]);

  useEffect(() => {
    handleSize();
  }, [ref?.offsetHeight, ref?.offsetWidth, viewportWidth, viewportHeight]);

  return [setRef, size];
}

export default useElementSize;

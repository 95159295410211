import React from "react";
import Group from "@components/arrangement/Group/Group";
import Social from "@components/navigation/Social/Social";

import { socials } from "@data/main/socials";

import "./Socials.scss";

const Socials = () => {
  return (
    <Group className="socials" alignItems="center">
      {socials.map((social, index) => (
        <Social {...social} key={index} />
      ))}
    </Group>
  );
};

export default Socials;

import Group from "@components/arrangement/Group/Group";
import Stack from "@components/arrangement/Stack/Stack";
import Button from "@components/dataEntry/Buttons/Button";
import Link from "@components/navigation/Link/Link";
import { paths } from "@data/paths";
import Card from "@pages/Main/shared/components/Card/Card";
import Navbar from "@pages/shared/Navbar/Navbar";
import React from "react";
import { Helmet } from "react-helmet-async";
import { Navigate } from "react-router-dom";

const Development = () => {
  const appStage = process.env.REACT_APP_STAGE;
  if (appStage === "prod") return <Navigate to={paths.main.path} />;

  return <></>;
};

export default Development;

import { NavLinkProps } from "@components/navigation/NavMenu/NavLink";
import { paths } from "@data/paths";
import { LAST_CASE_STUDY_PATH_KEY } from "@pages/CaseStudy/shared/TabsContext";
import React from "react";
import { useLocation } from "react-router-dom";
import { getSessionStorage } from "src/utils/storageManager";

interface IContext {
  children: React.ReactNode;
}
const NavigationContext = React.createContext(null as any);

const firstPartOfPath = (fullPath: string) => {
  return fullPath.split("/")[1];
};

export const NavigationProvider = ({ children }: IContext) => {
  const location = useLocation();

  const navigation: NavLinkProps[] = React.useMemo(
    () =>
      Object.values(paths)
        .map((pathObj) => {
          const lastCaseStudyPath = getSessionStorage(LAST_CASE_STUDY_PATH_KEY);
          if (!lastCaseStudyPath && pathObj.path === paths.caseStudy.path) {
            const toReturn = { ...pathObj, pathForNav: paths.lifestyle.pathForNav };
            return toReturn;
          }
          if (!!lastCaseStudyPath && pathObj.path === paths.caseStudy.path) {
            const toReturn = { ...pathObj, pathForNav: lastCaseStudyPath };
            return toReturn;
          }

          return pathObj;
        })
        .filter((path) => path.appearInNav)
        .map((navEntry) => {
          return {
            ...navEntry,
            active: firstPartOfPath(navEntry.path) === firstPartOfPath(location.pathname)
          };
        }),
    [location]
  );

  return <NavigationContext.Provider value={{ navigation }}>{children}</NavigationContext.Provider>;
};

export const useNavigationCtx = () => React.useContext(NavigationContext);

import { useState } from "react";
import { useTranslation } from "react-i18next";

import Group from "@components/arrangement/Group/Group";
import G2nButton from "@components/dataEntry/Buttons/Button";
import CollapseArrow from "src/assets/CollapseArrow";
import classNames from "classnames";

import "./ShowMore.scss";

interface ShowMoreProps {
  onExpand?(): void;
}

const ShowMore = ({ onExpand }: ShowMoreProps) => {
  const [active, setActive] = useState(false);
  const { t } = useTranslation("common");

  const handleToggle = () => {
    setActive((prevState) => !prevState);

    if (!active) {
      onExpand && onExpand();
    }
  };

  const classes = classNames("footer-show-more", { "footer-show-more-active": active });

  return (
    <G2nButton variant="outline-btn" action={handleToggle} className={classes}>
      <Group colGap={8} fullSize justifyContent="center" alignItems="center">
        <CollapseArrow className="footer-show-more-icon" /> {t("showMore")}
      </Group>
    </G2nButton>
  );
};

export default ShowMore;

import React from "react";

import { DataProviderProps } from "@pages/Entertainment/DataProviderProps";
import { EntertainmentCategories } from "@pages/Entertainment/categories";
import useExtras, { ExtrasReturn } from "@pages/Entertainment/hooks/useExtras";

interface IExtrasDataCtx {
  extras: ExtrasReturn;
  category: EntertainmentCategories;
}

const ExtrasDataCtx = React.createContext(null as any);

export const GamingExtrasDataProvider = ({ children }: DataProviderProps) => {
  const category = "gaming";
  const extras = useExtras({ category });
  return <ExtrasDataCtx.Provider value={{ extras, category }}>{children}</ExtrasDataCtx.Provider>;
};

export const useGamingExtrasData = (): IExtrasDataCtx => React.useContext(ExtrasDataCtx);

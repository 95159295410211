import { MediaType } from "src/integrations/backend/go2nft.dto";
import { PossibleSocials } from "../socials";

export const socialsMap: Record<SocialMediaType, PossibleSocials> = {
  facebook: "fb",
  instagram: "ig",
  soundcloud: "sound-cloud",
  spotify: "spotify",
  twitter: "x",
  youtube: "yt",
  discord: "discord",
  tiktok: "tik-tok"
};

export type SocialMediaType = Exclude<MediaType, "website">;

import React from "react";

const Facebook = () => {
  return (
    <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_123_21762)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.66864 8.04292C0.66864 11.8912 3.46358 15.0912 7.119 15.7401V10.1496H5.18389V7.9997H7.119V6.2794C7.119 4.34429 8.36585 3.26966 10.1294 3.26966C10.688 3.26966 11.2904 3.35545 11.849 3.44124V5.41956H10.8602C9.91394 5.41956 9.69914 5.89237 9.69914 6.49484V7.9997H11.7633L11.4194 10.1496H9.69914V15.7401C13.3546 15.0912 16.1495 11.8919 16.1495 8.04292C16.1495 3.76182 12.6663 0.259277 8.40907 0.259277C4.15183 0.259277 0.66864 3.76182 0.66864 8.04292Z"
          fill="#8D8D8B"
        />
      </g>
      <defs>
        <clipPath id="clip0_123_21762">
          <rect width="15.4809" height="15.4809" fill="white" transform="translate(0.667908 0.259277)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Facebook;

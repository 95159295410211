import React from "react";
import Group from "@components/arrangement/Group/Group";
import "./Task.scss";
import Button from "@components/dataEntry/Buttons/Button";

interface TaskProps {
  taskMessage: string;
  onComplete(): void;
  onChange(e: string): void;
  onActive(): void;
  isActive: boolean;
}

const Task = ({ taskMessage, onComplete, onChange, onActive, isActive }: TaskProps) => {
  const [contentEditable, setContentEditable] = React.useState(false);
  const [showHighlightButton, setShowHighlightButton] = React.useState(false);
  const [localModify, setLocalModify] = React.useState(taskMessage);

  const showButton = () => setShowHighlightButton(true);
  const hideButton = () => setShowHighlightButton(false);

  const handleEndContentEditable = (e: React.KeyboardEvent<HTMLParagraphElement>) => {
    if (e.key === "Enter" || e.key === "Escape") {
      endContentEditable();
    }
  };

  const endContentEditable = () => {
    setContentEditable(false);
    onChange(localModify);
  };

  return (
    <Group justifyContent="space-between" onMouseEnter={showButton} onMouseLeave={hideButton} fullSize className="task">
      <Group colGap={8} className="max-width">
        <input type="checkbox" defaultChecked={false} onChange={onComplete} />
        <p
          className={`p3 message${isActive ? " medium" : ""} p1`}
          contentEditable={contentEditable}
          onMouseDown={() => setContentEditable(true)}
          onBlur={endContentEditable}
          suppressContentEditableWarning={true}
          onInputCapture={(e) => {
            setLocalModify(e.currentTarget.textContent || "");
          }}
          onKeyDown={(e) => handleEndContentEditable(e)}
        >
          {taskMessage}
        </p>
      </Group>
      <button className={`button${showHighlightButton ? " show" : ""}`} onClick={onActive}>
        {isActive ? "Stop" : "Start"}
      </button>
    </Group>
  );
};

export default Task;

import React from "react";

const Medium = () => {
  return (
    <svg width="21" height="22" viewBox="0 0 21 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_123_21765)">
        <path
          d="M12.0964 11.0001C12.0964 14.1724 9.51768 16.7448 6.33512 16.7448C5.57971 16.7458 4.8315 16.598 4.13321 16.3098C3.43491 16.0217 2.80021 15.5988 2.26535 15.0654C1.73048 14.5319 1.30592 13.8983 1.01591 13.2008C0.725894 12.5033 0.576109 11.7555 0.575104 11.0001C0.575104 7.82645 3.15384 5.25538 6.33512 5.25538C7.09064 5.2542 7.83899 5.40187 8.53743 5.68995C9.23587 5.97803 9.87072 6.40087 10.4057 6.93433C10.9407 7.4678 11.3654 8.10142 11.6555 8.79903C11.9456 9.49664 12.0954 10.2446 12.0964 11.0001V11.0001ZM18.4156 11.0001C18.4156 13.9873 17.1262 16.4078 15.5356 16.4078C13.9449 16.4078 12.6556 13.986 12.6556 11.0001C12.6556 8.01283 13.9449 5.5924 15.5356 5.5924C17.1262 5.5924 18.4156 8.01411 18.4156 11.0001ZM21.0007 11.0001C21.0007 13.6758 20.5475 15.8448 19.9871 15.8448C19.4279 15.8448 18.9747 13.6746 18.9747 11.0001C18.9747 8.32432 19.4279 6.15538 19.9884 6.15538C20.5475 6.15538 21.0007 8.32432 21.0007 11.0001Z"
          fill="#8D8D8B"
        />
      </g>
      <defs>
        <clipPath id="clip0_123_21765">
          <rect width="20.4256" height="20.4256" fill="white" transform="translate(0.574371 0.787109)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Medium;

import React from "react";

import { DataProviderProps } from "@pages/Entertainment/DataProviderProps";
import { EntertainmentCategories } from "@pages/Entertainment/categories";

import useExtras, { ExtrasReturn } from "../../hooks/useExtras";

interface IExtrasDataCtx {
  extras: ExtrasReturn;
  category: EntertainmentCategories;
}

const ExtrasDataCtx = React.createContext(null as any);

export const MusicExtrasDataProvider = ({ children }: DataProviderProps) => {
  const category = "music";

  const extras = useExtras({ category });
  return <ExtrasDataCtx.Provider value={{ extras, category }}>{children}</ExtrasDataCtx.Provider>;
};

export const useMusicExtrasData = (): IExtrasDataCtx => React.useContext(ExtrasDataCtx);

import axios from "axios";

import { API_URL, APP_STAGE, AppStage } from "@config/config";
import { ExtrasDTO, SetDTO, PaginatedSetDTO } from "./go2nft.dto";
import { EntertainmentCategories } from "@pages/Entertainment/categories";

const BACKEND_URL_DEV = "http://172.16.0.115:3000/";
const BACKEND_URL_STAGE = "https://go2nft-api-staging-ps7md.ondigitalocean.app/";
const BACKEND_URL_PROD = API_URL;

const backendUrlPerAppStage: Record<Exclude<AppStage, null>, string> = {
  dev: BACKEND_URL_DEV,
  stage: BACKEND_URL_STAGE,
  prod: BACKEND_URL_PROD
};

const BACKEND_URL = backendUrlPerAppStage[APP_STAGE || "dev"];

export const WEBSOCKET_BACKEND_URL = `${BACKEND_URL}`.replace("http", "ws").replace("https", "ws");

const axiosInstance = axios.create({
  baseURL: BACKEND_URL,
  timeout: 6000
});

const getSetsTHROWS = async (category: EntertainmentCategories): Promise<SetDTO[]> => {
  const URL = `/v1/content/sets?category=${category}`;
  const res = await axiosInstance.get<PaginatedSetDTO>(URL);
  return res.data.data;
};

const getSetByIdTHROWS = async (id: string) => {
  const URL = `/v1/content/sets/${id}`;
  const res = await axiosInstance.get<SetDTO>(URL);
  return res.data;
};

const getExtrasTHROWS = async (jwt: string) => {
  const URL = `/v1/content/extras?token=${jwt}`;
  const res = await axiosInstance.get<ExtrasDTO>(URL);
  return res.data;
};

const go2nftAPI = {
  getSetsTHROWS,
  getSetByIdTHROWS,
  getExtrasTHROWS
};

export default go2nftAPI;

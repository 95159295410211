import layoutEn from "@locales/en/layout.json";
import mainEn from "@locales/en/main.json";
import sportLifestyleEn from "@locales/en/sportLifestyle.json";
import fashionEn from "@locales/en/fashion.json";
import beautyEn from "@locales/en/beauty.json";
import brandsEn from "@locales/en/brands.json";
import businessEn from "@locales/en/business.json";
import nftEn from "@locales/en/nft.json";
import notFoundEn from "@locales/en/notFound.json";
import articlesEn from "@locales/en/articles.json";
import partnersEn from "@locales/en/partners.json";
import formErrorsEn from "@locales/en/formErrors.json";
import commonEn from "@locales/en/common.json";
import navbarEn from "@locales/en/navbar.json";

import layoutPl from "@locales/pl/layout.json";
import mainPl from "@locales/pl/main.json";
import sportLifestylePl from "@locales/pl/sportLifestyle.json";
import fashionPl from "@locales/pl/fashion.json";
import beautyPl from "@locales/pl/beauty.json";
import brandsPl from "@locales/pl/brands.json";
import businessPl from "@locales/pl/business.json";
import nftPl from "@locales/pl/nft.json";
import notFoundPl from "@locales/pl/notFound.json";
import articlesPl from "@locales/pl/articles.json";
import partnersPl from "@locales/pl/partners.json";
import formErrorsPl from "@locales/pl/formErrors.json";
import commonPl from "@locales/pl/common.json";
import navbarPl from "@locales/pl/navbar.json";

export const resources = {
  en: {
    layout: layoutEn,
    main: mainEn,
    sportLifestyle: sportLifestyleEn,
    fashion: fashionEn,
    beauty: beautyEn,
    brands: brandsEn,
    business: businessEn,
    nft: nftEn,
    notFound: notFoundEn,
    articles: articlesEn,
    partners: partnersEn,
    formErrors: formErrorsEn,
    common: commonEn,
    navbar: navbarEn
  },
  pl: {
    layout: layoutPl,
    main: mainPl,
    sportLifestyle: sportLifestylePl,
    fashion: fashionPl,
    beauty: beautyPl,
    brands: brandsPl,
    business: businessPl,
    nft: nftPl,
    notFound: notFoundPl,
    partners: partnersPl,
    articles: articlesPl,
    formErrors: formErrorsPl,
    common: commonPl,
    navbar: navbarPl
  }
} as const;

import { useEffect, useState } from "react";
import useSessionStorage from "./useSessionStorage";
import useBoolean from "./useBoolean";

const useAsyncGetSessionStored = <T,>(fn: (args?: any) => Promise<T>, storageKey: string, args?: any) => {
  const [value, setValue] = useSessionStorage<T | null>(null, `${storageKey}`);
  // const [value, setValue] = useState<T | null>(null);
  const [isLoading, start, stop] = useBoolean(!value);

  const trigger = async () => {
    start();
    await fn(args)
      .then(setValue)
      .catch((e) => console.log(e))
      .finally(stop);
  };

  useEffect(() => {
    if (!!value) return;

    trigger();
  }, [value, args]);

  return { isLoading, value };
};

export default useAsyncGetSessionStored;

import { useEffects } from "@contexts/Effects";

import Navigation from "./Navigation/Navigation";
import Socials from "./Socials/Socials";
import ReloadPageLogo from "../ReloadPageLogo/ReloadPageLogo";
import MobileNavigation from "./Navigation/MobileNavigation";

import Group from "@components/arrangement/Group/Group";

import "./Navbar.scss";

const Navbar = () => {
  const { scrollDirection, toggleMobileNav, showMobileMenu } = useEffects();

  return (
    <>
      <nav className={`navbar ${scrollDirection} relative`}>
        <Group justifyContent="space-between" fullSize className="nav-max-width">
          <ReloadPageLogo />
          <Group colGap={32} className="full-height d-xs-n d-lg-f">
            <Navigation />
            <Socials />
          </Group>
          <button className="d-xs-b d-lg-n hamburger" onClick={toggleMobileNav}>
            <img src="/images/hamburger.svg" alt="Open mobile menu" width={24} height={24} />
          </button>
          <MobileNavigation show={showMobileMenu} className="d-xs-b d-lg-n" />
        </Group>
      </nav>
    </>
  );
};

export default Navbar;

import React from "react";
import "./Grid.scss";

interface GridProps {
  size?: "xl" | "xxl";
  colGap?: number;
  rowGap?: number;
  children: React.ReactNode;
  className?: string;
  fullHeight?: boolean;
}

const Grid = ({ children, size = "xl", rowGap, colGap, className, fullHeight }: GridProps) => {
  const classes = `rt-container-${size}${className ? ` ${className}` : ""}${fullHeight ? ` full-height` : ""}`;
  return (
    <div className={classes}>
      <div
        className={`rt-grid ${className ? ` ${className}` : ""}`}
        style={{ rowGap: `${rowGap}px`, columnGap: `${colGap}px` }}
      >
        {children}
      </div>
    </div>
  );
};

export default Grid;

import React from "react";
import Footer from "@pages/shared/Footer/Footer";
import Navbar from "@pages/shared/Navbar/Navbar";
import Layout from "../Layout";
import { IChildren } from "@components/interfaces/IChildren";

const LayoutNavMainFooter = ({ children }: IChildren) => {
  return (
    <Layout variant={"navbar-content-footer"} Navbar={Navbar} Footer={Footer}>
      {children}
    </Layout>
  );
};

export default LayoutNavMainFooter;

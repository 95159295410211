import React from "react";
import { useTranslation } from "react-i18next";

import { ISocial } from "@data/main/socials";
import "./Social.scss";

const Social = ({ img: Img, rel, target, title, url }: ISocial) => {
  const { t } = useTranslation("layout");

  return (
    <a href={url} rel={rel} title={t(title as any)} target={target} className="social-link">
      <Img />
    </a>
  );
};

export default Social;

import React, { CSSProperties } from "react";
import { IChildren } from "@components/interfaces/IChildren";
import { BaseProps } from "@components/interfaces/BaseProps";
import { classNames } from "src/utils/utils";

import "./Group.scss";

export type IGroup = {
  colGap?: number;
  rowGap?: number;
  justifyContent?: CSSProperties["justifyContent"];
  alignItems?: CSSProperties["alignItems"];
  className?: string;
  grid?: boolean;
  fullSize?: boolean;
  fullWidth?: boolean;
  fullHeight?: boolean;
  reff?: React.RefObject<HTMLDivElement>;
  onMouseEnter?(): void;
  onMouseLeave?(): void;
} & BaseProps;

const Group = ({
  colGap,
  rowGap,
  children,
  justifyContent = "flex-start",
  alignItems = "center",
  grid = false,
  fullSize = false,
  fullWidth = false,
  fullHeight = false,
  className = "",
  reff,
  onMouseEnter,
  onMouseLeave,
  ...rest
}: IGroup & IChildren) => {
  const classes = classNames(
    "group",
    className,
    grid ? " grid full-size" : "",
    fullSize && !grid ? " full-size" : "",
    fullWidth ? "full-width" : "",
    fullHeight ? "full-height" : ""
  );

  const isGap = !!rowGap || !!colGap;

  return (
    <div
      {...rest}
      className={classes}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      style={{
        gap: isGap ? `${rowGap ? rowGap : `0`}px ${colGap ? colGap : "0"}px` : undefined,
        justifyContent,
        alignItems,
        ...rest.style
      }}
      ref={reff}
    >
      {children}
    </div>
  );
};

export default Group;

import React from "react";
import { useCollapseComp } from "../Collapse";

interface CollapseTargetProps {
  children: React.ReactNode;
}

const CollapseTarget = ({ children }: CollapseTargetProps) => {
  const { handleToggleExpanded } = useCollapseComp();

  return (
    <div role="button" className="collapse-target" onClick={handleToggleExpanded}>
      {children}
    </div>
  );
};

export default CollapseTarget;

import Stack from "@components/arrangement/Stack/Stack";
import { APP_STAGE } from "@config/config";
import React, { KeyboardEvent } from "react";
import Task from "./Task";
import { getLocalStorage, writeLocalStorage } from "src/utils/storageManager";
import "./TaskManager.scss";

const LOCAL_STORAGE_CURRENT_TASKS = "*b5e4SWvbN(*uj,m7*rbnDX%VE7487nH(8n90h80798^67$5764%$#^57%";

const CurrentTasks = () => {
  return <></>;
  if (APP_STAGE === "prod" || !APP_STAGE) return <></>;
  const tasksFromLocalStorage = getLocalStorage(LOCAL_STORAGE_CURRENT_TASKS) || [];
  const [tasks, setTasks] = React.useState<string[]>(tasksFromLocalStorage?.tasks || []);
  const [newTask, setNewTask] = React.useState("");
  const [activeTask, setActiveTask] = React.useState<number>(tasksFromLocalStorage?.activeTask || -1);

  const completeTask = (taskId: number) => {
    setTasks((prev) => prev.filter((_, index) => index !== taskId));
    if (activeTask === taskId) {
      resetActiveTask();
      return;
    }
  };

  const addTask = (e: KeyboardEvent) => {
    if (e.key === "Enter") {
      setTasks((prev) => [...prev, newTask]);
      setNewTask("");
    }
  };

  const writeNewTask = (task: string) => {
    setNewTask(task);
  };

  const editTask = (newTaskVersion: string, taskId: number) => {
    setTasks((prev) =>
      prev.map((task: string, id: number) => {
        if (id === taskId) {
          return newTaskVersion;
        }
        return task;
      })
    );
  };
  const resetActiveTask = () => setActiveTask(-1);

  const toggleActiveTask = (index: number) => {
    if (activeTask === index) {
      resetActiveTask();
      return;
    }
    setActiveTask(index);
  };

  React.useEffect(() => {
    writeLocalStorage(LOCAL_STORAGE_CURRENT_TASKS, { tasks, activeTask });
  }, [tasks, activeTask]);

  return (
    <div className="current-tasks">
      <h4 className="header">Task manager</h4>
      <Stack className="body" fullSize rowGap={16}>
        {tasks.length !== 0 && (
          <Stack fullSize rowGap={8} className="direct-tasks-wrapper">
            {tasks.map((task, index) => (
              <Task
                taskMessage={task}
                onComplete={() => completeTask(index)}
                onChange={(e) => editTask(e, index)}
                onActive={() => toggleActiveTask(index)}
                isActive={activeTask === index}
                key={index}
              />
            ))}
          </Stack>
        )}
        <input
          value={newTask}
          onChange={(e) => writeNewTask(e.currentTarget.value)}
          onKeyDown={(e) => addTask(e)}
          placeholder="Write here new task and tap ENTER"
          className="custom-input"
        />
      </Stack>
    </div>
  );
};

export default CurrentTasks;

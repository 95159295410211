import React from "react";

import { IChildren } from "@components/interfaces/IChildren";
import { useParams } from "react-router-dom";
import { productSlug } from "../../slugs";
import { DataProviderProps } from "@pages/Entertainment/DataProviderProps";
import useProduct, { ProductReturn } from "@pages/Entertainment/hooks/useProductDetails";
import { EntertainmentCategories } from "@pages/Entertainment/categories";

interface IProductDataCtx {
  product: ProductReturn;
  category: EntertainmentCategories;
}

const ProductDataCtx = React.createContext(null as any);

export const GamingProductDataProvider = ({ children }: DataProviderProps) => {
  const { productId } = useParams<typeof productSlug>();

  const category = "gaming";

  const product = useProduct({ id: productId || "", category });

  return <ProductDataCtx.Provider value={{ product, category }}>{children}</ProductDataCtx.Provider>;
};

export const useGamingProductData = (): IProductDataCtx => React.useContext(ProductDataCtx);

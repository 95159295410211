import React from "react";

const X = () => {
  return (
    <svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.18707 17C12.6054 17 16.1871 13.4183 16.1871 9C16.1871 4.58172 12.6054 1 8.18707 1C3.7688 1 0.187073 4.58172 0.187073 9C0.187073 13.4183 3.7688 17 8.18707 17Z"
        fill="#8D8D8B"
        stroke="white"
        strokeWidth="0.0166206"
        strokeMiterlimit="10"
      />
      <path
        d="M3.25905 4.36523L7.08296 9.47807L3.23495 13.6351H4.10105L7.47005 9.99547L10.192 13.6351H13.1392L9.10004 8.23468L12.6818 4.36523H11.8157L8.71311 7.71711L6.20622 4.36523H3.25905ZM4.53269 5.00313H5.88661L11.8654 12.9972H10.5115L4.53269 5.00313Z"
        fill="white"
      />
    </svg>
  );
};

export default X;

import { useTranslation } from "react-i18next";
import { useNavLogo } from "@contexts/NavLogoManager";
import { paths } from "@data/paths";
import "./ReloadPageLogo.scss";

const reloadToMain = () => {
  window.open(paths.main.path, "_self");
};

const ReloadPageLogo = () => {
  const { navLogoAppear } = useNavLogo();
  const { t } = useTranslation("common");

  const classes = `reload-page-logo${navLogoAppear ? "" : " hide"} d-xs-b`;

  return (
    <button onClick={reloadToMain} className={classes}>
      <img src="/images/nav-logo.svg" alt="Go2NFT platform logo" title={t("goToMainPage")} width={67} height={24} />
    </button>
  );
};

export default ReloadPageLogo;

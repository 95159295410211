import React from "react";
import { useScrollDirection } from "@hooks/useScrollDirection";
import { useWindowDimensions } from "@hooks/useWindowDimensions";
import { useLocation } from "react-router-dom";

interface IContext {
  children: React.ReactNode;
}

const Effects = React.createContext(null as any);

export const EffectsProvider = ({ children }: IContext) => {
  const scrollDirection = useScrollDirection();
  const { width } = useWindowDimensions();
  const location = useLocation();

  const [showMobileMenu, setShowMobileMenu] = React.useState(false);

  const scrollTop = () => {
    window.scrollTo(0, 0);
  };

  const toggleMobileNav = (e: any) => {
    e.stopPropagation();
    if (showMobileMenu) {
      hideMenu();
    }
    if (!showMobileMenu) {
      showMenu();
    }
  };
  const showMenu = () => setShowMobileMenu(true);
  const hideMenu = () => setShowMobileMenu(false);

  React.useEffect(() => {
    hideMenu();
  }, [width, location]);

  React.useEffect(() => {
    const body = document.body;
    if (!body) return;

    if (showMobileMenu) {
      body.style.overflow = "hidden";
    } else {
      body.style.overflow = "auto";
    }

    const handleClickBody = (e: any) => {
      e.stopPropagation();
      if (showMobileMenu) {
        hideMenu();
      }
    };

    body.addEventListener("click", handleClickBody);

    return () => body.removeEventListener("click", handleClickBody);
  }, [showMobileMenu]);

  React.useEffect(() => {
    scrollTop();
  }, [location]);

  return (
    <Effects.Provider
      value={{
        scrollDirection,
        toggleMobileNav,
        hideMenu,
        showMenu,
        showMobileMenu
      }}
    >
      {children}
    </Effects.Provider>
  );
};

export const useEffects = () => React.useContext(Effects);

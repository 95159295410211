export const writeLocalStorage = (key: string, value: any) => {
  window.localStorage.setItem(key, JSON.stringify(value));
};

export const getLocalStorage = (key: string) => {
  return JSON.parse(window.localStorage.getItem(key) || "null");
};

export const removeLocalStorage = (key: string) => {
  window.localStorage.removeItem(key);
};

// export const updateLocalStorage = (key: string, value: any) => {
//   const existingItems = getLocalStorage(key);
// };

export const writeSessionStorage = (key: string, value: any) => {
  window.sessionStorage.setItem(key, JSON.stringify(value));
};

export const getSessionStorage = (key: string) => {
  const sessionStorageItem = window.sessionStorage.getItem(key);
  return JSON.parse(sessionStorageItem || "null");
};

export const removeSessionStorage = (key: string) => {
  window.sessionStorage.removeItem(key);
};

// export const updateSessionStorage = (key: string, value: any) => {
//   const existingItems = getLocalStorage(key);
// };

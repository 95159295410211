import React from "react";
import { useWindowDimensions } from "../hooks/useWindowDimensions";
import { useLocation } from "react-router-dom";
import useInterval from "../hooks/useInterval";

interface IContext {
  children: React.ReactNode;
}

const PageHeightContext = React.createContext(null as any);

export const PageDimensionsProvider = ({ children }: IContext) => {
  const [pageHeight, setPageHeight] = React.useState(0);
  const [pageWidth, setPageWidth] = React.useState(0);

  const refreshPageDimensions = () => {
    const page = document.querySelector(".layout");
    if (!page) return;

    const rect = page.getBoundingClientRect();
    if (rect.height !== pageHeight) {
      setPageHeight(rect.height);
    }
    if (rect.width !== pageWidth) {
      setPageWidth(rect.width);
    }
  };

  useInterval(refreshPageDimensions, 1000);

  return (
    <PageHeightContext.Provider value={{ pageHeight, pageWidth, refreshPageDimensions }}>
      {children}
    </PageHeightContext.Provider>
  );
};

export const usePageDimensions = () => React.useContext(PageHeightContext);

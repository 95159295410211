import React from "react";

const Telegram = () => {
  return (
    <svg width="19" height="16" viewBox="0 0 19 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.0703 12.2695C9.40449 12.9395 8.74445 13.5985 8.09056 14.2626C7.86197 14.4952 7.60495 14.6239 7.27685 14.606C7.05287 14.5937 6.92763 14.4921 6.85886 14.2725C6.35788 12.666 5.8496 11.0635 5.3513 9.45541C5.3029 9.2994 5.23144 9.22358 5.07853 9.17476C3.8983 8.8024 2.72038 8.42132 1.54439 8.03508C1.36343 7.97553 1.18094 7.89773 1.02458 7.78777C0.782925 7.61866 0.747579 7.34277 0.964261 7.14429C1.16442 6.96129 1.40339 6.80171 1.65157 6.70009C3.0942 6.10941 4.54451 5.53779 5.99251 4.961C9.66727 3.49502 13.342 2.03023 17.0172 0.564246C17.716 0.285181 18.2531 0.678174 18.1805 1.459C18.134 1.95838 18.0088 2.44982 17.9077 2.94284C17.1098 6.83585 16.311 10.7293 15.5088 14.6215C15.3183 15.5456 14.6901 15.787 13.954 15.2261C12.7212 14.2872 11.4895 13.3452 10.2574 12.4048C10.1986 12.3608 10.1375 12.3183 10.0703 12.2695ZM7.30298 13.4516C7.31681 13.4477 7.33064 13.4437 7.34447 13.4401C7.35215 13.4036 7.3633 13.3679 7.36637 13.3317C7.44551 12.4493 7.52927 11.5672 7.59803 10.6832C7.6134 10.4863 7.67871 10.3466 7.82202 10.214C8.95844 9.16404 10.088 8.10732 11.2198 7.05299C12.4726 5.88552 13.7258 4.71925 14.9741 3.54702C15.0513 3.47477 15.0836 3.35171 15.1366 3.25168C15.019 3.23858 14.893 3.18975 14.7862 3.21834C14.6406 3.25724 14.5027 3.34457 14.3728 3.42912C11.6078 5.22855 8.84511 7.03116 6.07819 8.82702C5.92182 8.92864 5.89262 9.01478 5.95217 9.19222C6.15618 9.79759 6.34174 10.4085 6.53422 11.0182C6.79047 11.8296 7.04672 12.6406 7.30298 13.4516Z"
        fill="#8D8D8B"
      />
    </svg>
  );
};

export default Telegram;

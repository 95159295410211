import Facebook from "src/assets/Facebook";
import Instagram from "src/assets/Instagram";
import Medium from "src/assets/Medium";
import Telegram from "src/assets/Telegram";
import X from "src/assets/X";
import Youtube from "src/assets/Youtube";
import { FACEBOOK_URL, INSTAGRAM_URL, MEDIUM_URL, TELEGRAM_URL, TWITTER_URL, YOUTUBE_URL } from "src/constants/socials";

export interface ISocial {
  img: any;
  title: string;
  url: string;
  rel: string;
  target: string;
}

export const socials: ISocial[] = [
  {
    img: Telegram,
    title: "socials.telegram",
    url: TELEGRAM_URL
  },
  {
    img: Instagram,
    title: "socials.instagram",
    url: INSTAGRAM_URL
  },
  {
    img: Facebook,
    title: "socials.facebook",
    url: FACEBOOK_URL
  },
  {
    img: Youtube,
    title: "socials.youtube",
    url: YOUTUBE_URL
  },
  {
    img: Medium,
    title: "socials.medium",
    url: MEDIUM_URL
  },
  {
    img: X,
    title: "socials.twitter",
    url: TWITTER_URL
  }
].map((social) => ({ ...social, rel: "nofollow noreferrer noopener", target: "_blank" }));

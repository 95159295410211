export type AppStage = "dev" | "prod" | "stage" | null;

export const APP_STAGE: AppStage = (process.env.REACT_APP_STAGE as AppStage) || null;

const MAILING_SERVICE_URL_ENV = process.env.REACT_APP_MAILING_URL || null;
const MAILING_CAPTCHA_KEY_ENV = process.env.REACT_APP_MAILING_RECAPTCHA_KEY || null;
const MAILING_API_KEY_ENV = process.env.REACT_APP_MAILING_API_KEY || null;

const getMailingServiceUrl = () => {
  if (!MAILING_SERVICE_URL_ENV) {
    throw new Error("Mailing url not provided");
  }

  return MAILING_SERVICE_URL_ENV;
};

const getMailingCaptchaKey = () => {
  if (!MAILING_CAPTCHA_KEY_ENV) {
    throw new Error("Mailing captcha key not provided");
  }

  return MAILING_CAPTCHA_KEY_ENV;
};

const getMailingApiKey = () => {
  if (!MAILING_API_KEY_ENV) {
    throw new Error("Mailing api key not provided");
  }

  return MAILING_API_KEY_ENV;
};

export const MAILING_SERVICE_URL = getMailingServiceUrl();
export const MAILING_CAPTCHA_KEY = getMailingCaptchaKey();
export const MAILING_API_KEY = getMailingApiKey();

export const API_URL =
  process.env.REACT_APP_API_URL ||
  (() => {
    throw new Error("REACT_APP_API_URL not provided");
  })();

export const I18N_DEBUG_MODE = process.env.REACT_APP_I18N_DEBUG_MODE === "true";
export const MAILING_SERVICE_TIMEOUT = 8000; //[ms]

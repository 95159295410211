import { paths } from "@data/paths";
import React from "react";
import { useLocation } from "react-router-dom";

interface IContext {
  children: React.ReactNode;
}

const NavLogoManager = React.createContext(null as any);

export const NavLogoManagerProvider = ({ children }: IContext) => {
  const location = useLocation();

  const [navLogoAppear, setNavLogoAppear] = React.useState(location.pathname !== paths.main.path);

  const showLogo = () => setNavLogoAppear(true);
  const hideLogo = () => setNavLogoAppear(false);

  React.useEffect(() => {
    if (location.pathname === paths.main.path) {
      hideLogo();
    } else {
      showLogo();
    }
  }, [location]);

  return <NavLogoManager.Provider value={{ navLogoAppear, showLogo, hideLogo }}>{children}</NavLogoManager.Provider>;
};

export const useNavLogo = () => React.useContext(NavLogoManager);

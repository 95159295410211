import Stack from "@components/arrangement/Stack/Stack";
import React from "react";
import NavLink, { NavLinkProps } from "@components/navigation/NavMenu/NavLink";
import { useNavigationCtx } from "@contexts/NavigationContext";

interface MobNavProps {
  show: boolean;
  className?: string;
}

const MobileNavigation = ({ show, className }: MobNavProps) => {
  const { navigation } = useNavigationCtx();

  return (
    <div className={`mobile-navigation${show ? " show" : " hide"}${className ? ` ${className}` : ""}`}>
      <Stack alignItems="center">
        {navigation.map((nav: NavLinkProps, index: number) => (
          <NavLink {...nav} key={index} />
        ))}
      </Stack>
    </div>
  );
};

export default MobileNavigation;

import useAsyncGetSessionStored from "@hooks/useAsyncGetSessionStored";
import go2nftAPI from "src/integrations/backend/go2nft.api";
import { ExtrasDTO } from "src/integrations/backend/go2nft.dto";
import { readCookie } from "src/utils/cookies";
import { EXTRAS_JWT_COOKIE } from "../constants";
import { BackendIExtra } from "../extras";
import { socialsMap } from "@pages/Entertainment/integrations/SocialsMap";
import { CategoryProp } from "../CategoryProp";

const EXTRAS_KEY = "extras_key";

export interface ExtrasReturn {
  isLoading: boolean;
  value: BackendIExtra | null;
}

const extrasTransformer = (value: ExtrasDTO | null) => {
  if (!value) return value;

  const transformedPhotos = value.images.map((img) => `${img.fileUrl}?size=raw_compressed`);
  const transformedMainImage = value.setImages
    .filter((i) => i.main)
    .map((i) => ({ ...i, fileUrl: `${i.fileUrl}?size=raw` }))[0];
  const transformedSoundClouds = (value.mediaUrls || [])
    .filter((media) => media.type === "soundcloud")
    .map((sc) => ({ url: sc.url, description: sc.description! }));
  const transformedYtLinks = (value.mediaUrls || [])
    .filter((media) => media.type === "youtube")
    .map((media) => ({ img: media.imageUrl, title: media.title, description: media.description, url: media.url }));
  const transformedSocials = () => {
    const toReturn = {};
    value.socialUrls.forEach((social) => {
      const feKey = (socialsMap as any)[social.type as any];
      Object.assign(toReturn, { [feKey]: social.url });
    });
    return toReturn;
  };

  const transformed: BackendIExtra = {
    extraPhotos: transformedPhotos,
    mainImage: transformedMainImage,
    soundClouds: transformedSoundClouds,
    ytVideos: transformedYtLinks,
    description: value.description,
    title: value.title,
    artistLogo: value.partnerImage,
    artistLogoBg: value.partnerBgColor,
    socials: transformedSocials()
  };

  console.log(transformed);

  return transformed;
};

const useExtras = ({ category }: CategoryProp): ExtrasReturn => {
  const jwt = readCookie(`${category}-${EXTRAS_JWT_COOKIE}`);
  const toReturn = useAsyncGetSessionStored<ExtrasDTO>(
    go2nftAPI.getExtrasTHROWS,
    `${category}-${EXTRAS_KEY}-${jwt}`,
    jwt
  );

  const extras = {
    isLoading: toReturn.isLoading,
    value: extrasTransformer(toReturn.value)
  };

  return extras;
};

export default useExtras;

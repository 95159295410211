import React from "react";

import useProductsList, { ProductsReturn } from "../../hooks/useProductsList";
import { DataProviderProps } from "@pages/Entertainment/DataProviderProps";
import { EntertainmentCategories } from "@pages/Entertainment/categories";

interface IProductsDataCtx {
  products: ProductsReturn;
  category: EntertainmentCategories;
}

const ProductsDataCtx = React.createContext(null as any);

export const MusicProductsDataProvider = ({ children }: DataProviderProps) => {
  const category = "music";
  const products = useProductsList({ category });

  return <ProductsDataCtx.Provider value={{ products, category }}>{children}</ProductsDataCtx.Provider>;
};

export const useMusicProductsData = (): IProductsDataCtx => React.useContext(ProductsDataCtx);

import Stack from "@components/arrangement/Stack/Stack";
import React from "react";
import "./Input.scss";

export interface IInput {
  label: string;
  value: string;
  onChange(value: string): void;
  error: string;
  placeholder?: string;
  type?: "text" | "email" | "number" | "password" | "search" | "tel" | "url";
  className?: string;
  inputRequiremenets: string;
  autoFocus: boolean;
  required: boolean;
  fullSize: boolean;
  variant?: "primary" | "secondary";
  children?: React.ReactNode;
}

type Seal = "correct" | "wrong" | "default";

const Input = ({
  label,
  value,
  onChange,
  error,
  placeholder = "",
  type = "search",
  className,
  inputRequiremenets,
  autoFocus,
  required,
  fullSize,
  variant = "primary",
  children
}: IInput) => {
  const [focused, setFocused] = React.useState(false);
  const [hover, setHover] = React.useState(false);
  const inputRef = React.useRef(null as any);
  const id = React.useId();

  const inputState = (): Seal => {
    // if (value.trimEnd().trimStart().length > 0 && !error) {
    //   return "correct";
    // }
    if (!!error) {
      return "wrong";
    }

    return "default";
  };

  const focusInput = () => setFocused(true);
  const blurInput = () => setFocused(false);
  const handleHover = () => setHover(true);
  const handleUnhover = () => setHover(false);

  const inputClick = () => {
    focusInput();
    if (!inputRef.current) return;

    inputRef.current.focus();
  };
  const inputBlur = () => {
    blurInput();
    if (!inputRef.current) return;

    inputRef.current.blur();
  };

  return (
    <Stack
      rowGap={4}
      className={`total-input ${variant} ${inputState()}${className ? ` ${className}` : ""}${
        required ? " required" : ""
      }`}
      onClick={inputClick}
      onBlur={inputBlur}
      fullSize={fullSize}
    >
      {label && (
        <label className="p3 semiBold label" htmlFor={id}>
          {label}
        </label>
      )}
      <div
        className={`input-wrapper${hover ? " hover" : ""}${focused ? " focus" : ""} ${inputState()}`}
        onMouseEnter={handleHover}
        onMouseLeave={handleUnhover}
      >
        <input
          id={id}
          className={`input-itself`}
          value={value}
          onChange={(e) => onChange(e.currentTarget.value)}
          placeholder={placeholder}
          type={type}
          ref={inputRef}
          title={inputRequiremenets}
          autoFocus={autoFocus}
          autoComplete="off"
        />
        {inputState() !== "default" && <img src={`/images/${inputState()}.svg`} height={20} width={20} />}
        {children}
      </div>
      {!!error && <p className="p3 error">{error}</p>}
    </Stack>
  );
};

export default Input;

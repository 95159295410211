import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import Group from "@components/arrangement/Group/Group";
import Stack from "@components/arrangement/Stack/Stack";
import Grid from "@components/arrangement/Grid/Grid";
import GridCol from "@components/arrangement/Grid/GridCol";
import Link from "@components/navigation/Link/Link";
import Collapse from "@components/ux/Collapse/Collapse";

import { footerSocials } from "../../../constants/footerSocials";
import { APP_LINK_APP_STORE, APP_LINK_GOOGLE_PLAY } from "src/constants/linksToApp";
import { OFFICIAL_MAIL } from "../../../constants/mail";
import { LINK_TO_ARCHIVAL_MATERIALS } from "src/constants/externalLinks";
import { LOGO_GO2NFT } from "src/constants/socials";

import NewsLetter from "./Newsletter/Newsletter";
import ShowMore from "./ShowMore/ShowMore";
import "./Footer.scss";
import { LITEPAPER } from "src/constants/constants";

const Footer = () => {
  const [endFooterRef, setEndFooterRef] = useState<HTMLDivElement | null>(null);

  const { t } = useTranslation("layout");

  useEffect(() => {});

  const handleExpand = () => {
    if (endFooterRef) {
      setTimeout(() => {
        endFooterRef.scrollIntoView({ behavior: "smooth" });
      }, 400);
    }
  };

  return (
    <div className="footer">
      <Stack fullSize className="rg-xs-3 rg-lg-5">
        <Grid rowGap={25}>
          <GridCol className="md-4 xs-12">
            <h3 className="footer-header">{t("footer.socials.title")}</h3>
            <div className="social-media">
              {footerSocials.map(({ url, logo }) => {
                return (
                  <a href={url} target="_blank" key={Math.random()} className="social-link">
                    <img src={logo} alt={"social-media"} width="40" height="40" />
                  </a>
                );
              })}
            </div>
          </GridCol>

          <GridCol className="xs-12 md-4">
            <h3 className="footer-header">{t("footer.contact.title")}</h3>
            <p className="contact-title p3">{t("footer.contact.description")}</p>
            <a href={`mailto:${OFFICIAL_MAIL}`} className="contact-title p3 underline">
              {OFFICIAL_MAIL}
            </a>
          </GridCol>

          <GridCol className="xs-12 md-4">
            <h3 className="footer-header">{t("footer.litepaper.title")}</h3>
            <p className="p3 white-4 mb-xs-2">{t("footer.litepaper.description")}</p>
            <a href={LITEPAPER} download className="underline footer-header">
              {t("footer.litepaper.download")}
            </a>
          </GridCol>
        </Grid>

        <Collapse>
          <Collapse.Target>
            <Group justifyContent="center" fullSize>
              <ShowMore onExpand={handleExpand} />
            </Group>
          </Collapse.Target>

          <Collapse.Content>
            <Stack className="rg-xs-3 rg-lg-10 pt-xs-3 pt-lg-8">
              <Grid rowGap={48}>
                <GridCol className="md-4 xs-12">
                  <h3 className="mb-2 footer-header white-4">{t("footer.history.title")}</h3>
                  <p className=" p3 white-4 footer-box mb-2">{t("footer.history.description")}</p>

                  <a
                    className="p3 footer-title underline"
                    href={LINK_TO_ARCHIVAL_MATERIALS}
                    target="_blank"
                    rel="nofollow noopener noreferrer"
                  >
                    {t("footer.history.link")}
                  </a>
                </GridCol>

                <GridCol className="xs-12 md-4">
                  <NewsLetter />
                </GridCol>
              </Grid>

              <Grid rowGap={25}>
                <GridCol className="md-4 xs-12">
                  <h3 className="footer-header mb-2">{t("footer.security.title")}</h3>
                  <img
                    src="/images/footer/europe-flag.svg"
                    alt="europe-flag"
                    className="footer-img"
                    width="66"
                    height="41"
                  />
                  <p className="p3 footer-title mt-2">{t("footer.security.description")}</p>
                </GridCol>

                <GridCol className="md-4 xs-12">
                  <h3 className="footer-header mb-2">{t("footer.legalDisclaimer.title")}</h3>
                  <p className="p3 footer-title">{t("footer.legalDisclaimer.description")}</p>
                  <h3 className="footer-header mt-2">{t("footer.logo.title")}</h3>
                  <a
                    className="p3 footer-title underline"
                    href={`${LOGO_GO2NFT}`}
                    download={true}
                    // target={"_blank"}
                    rel="nofollow noopener noreferrer"
                  >
                    {t("footer.logo.link")}
                  </a>
                </GridCol>

                <GridCol className="md-4 xs-12">
                  <h3 className="footer-header mb-2"> {t("footer.downloadApp")}</h3>
                  <Group colGap={16} className="go2nft-app-links">
                    <a className="p3" target="_blank" href={`${APP_LINK_APP_STORE}`}>
                      <img src="/images/app-store.svg" alt="app store link" width="113" height="35" />
                    </a>
                    <a className="p3" target="_blank" href={`${APP_LINK_GOOGLE_PLAY}`}>
                      <img src="/images/google-play.svg" alt="google play link" width="113" height="35" />
                    </a>
                  </Group>
                </GridCol>
              </Grid>
            </Stack>
          </Collapse.Content>
        </Collapse>

        <Group fullSize className="nav-max-width">
          <img src="/images/go2nft-logo-white.svg" alt="Go2NFT logo" width="67" height="25" />
        </Group>
      </Stack>
      <div ref={setEndFooterRef} />
    </div>
  );
};

export default Footer;

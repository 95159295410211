import React from "react";

const Youtube = () => {
  return (
    <svg width="21" height="16" viewBox="0 0 21 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M20.1589 3.09839C20.0426 2.66711 19.8154 2.27379 19.4999 1.95758C19.1845 1.64138 18.7916 1.41331 18.3606 1.29609C16.7624 0.857249 10.3686 0.850105 10.3686 0.850105C10.3686 0.850105 3.97587 0.842961 2.37665 1.26241C1.9459 1.38503 1.5539 1.61632 1.23829 1.93407C0.922674 2.25183 0.694044 2.64539 0.574347 3.07696C0.152857 4.67516 0.148775 7.98993 0.148775 7.98993C0.148775 7.98993 0.144692 11.321 0.563121 12.9029C0.797849 13.7775 1.48673 14.4684 2.36236 14.7042C3.97689 15.143 10.3533 15.1502 10.3533 15.1502C10.3533 15.1502 16.7471 15.1573 18.3453 14.7389C18.7765 14.6219 19.1697 14.3943 19.4859 14.0788C19.8022 13.7632 20.0307 13.3705 20.1486 12.9396C20.5712 11.3425 20.5742 8.02871 20.5742 8.02871C20.5742 8.02871 20.5946 4.69659 20.1589 3.09839ZM8.32344 11.0608L8.32855 4.93744L13.6426 8.00422L8.32344 11.0608Z"
        fill="#8D8D8B"
      />
    </svg>
  );
};

export default Youtube;

import React from "react";
import "./Button.scss";

type ButtonVariant = "primary-btn" | "secondary-btn" | "trietary-btn" | "link-btn" | "outline-btn";

export interface ButtonProps {
  children: React.ReactNode;
  variant?: ButtonVariant;
  title?: string;
  className?: string;
  externalControlHovered?: boolean;
  disabled?: boolean;
  uppercase?: boolean;
  fullSize?: boolean;
  action?(): void;
  onMouseEnter?(): void;
  onMouseLeave?(): void;
}

const G2nButton = ({
  children,
  variant = "primary-btn",
  action = () => {},
  onMouseEnter = () => {},
  onMouseLeave = () => {},
  className = "",
  externalControlHovered = false,
  disabled = false,
  uppercase = true,
  fullSize = false,
  title = ""
}: ButtonProps) => {
  const classes = `g2n-button${className ? ` ${className}` : ""}${externalControlHovered ? " hover" : ""} ${variant}${
    uppercase ? " uppercase" : ""
  }${fullSize ? " full-size" : ""}`;

  return (
    <button
      onClick={action}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      className={classes}
      disabled={disabled}
      title={title}
    >
      {children}
    </button>
  );
};

export default G2nButton;

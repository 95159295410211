import layoutLocales from "@locales/en/layout.json";

export type IPathValue = {
  path: string;
  pathForNav: string;
  pathName: string;
  tKey?: `paths.${keyof (typeof layoutLocales)["paths"]}`;
  rel: string;
  appearInTabs?: boolean;
  appearInNav: boolean;
};

const pathsKeys = [
  "main",
  "caseStudy",
  "lifestyle",
  "fashion",
  "beauty",
  "brands",
  "partners",
  "business",
  "pricing",
  "nft",
  "campaign",
  "music",
  "gaming",
  // "blog",
  "development",
  "download",
  "notFound"
] as const;
type PathsKeys = (typeof pathsKeys)[number];

export const paths: Record<PathsKeys, IPathValue> = {
  main: {
    path: "/",
    pathForNav: "/",
    pathName: "Main",
    tKey: "paths.main",
    rel: "dofollow",
    appearInNav: true
  },
  caseStudy: {
    path: "/case-study",
    pathForNav: "/case-study",
    pathName: "NFT Case study",
    tKey: "paths.caseStudy",
    rel: "dofollow",
    appearInTabs: false,
    appearInNav: true
  },
  lifestyle: {
    path: "sport-lifestyle",
    pathForNav: "/case-study/sport-lifestyle",
    pathName: "Sport & Lifestyle",
    tKey: "paths.lifestyle",
    rel: "dofollow",
    appearInTabs: true,
    appearInNav: false
  },
  fashion: {
    path: "fashion",
    pathForNav: "/case-study/fashion",
    pathName: "Fashion",
    tKey: "paths.fashion",
    rel: "dofollow",
    appearInTabs: true,
    appearInNav: false
  },
  beauty: {
    path: "beauty",
    pathForNav: "/case-study/beauty",
    pathName: "Beauty",
    tKey: "paths.beauty",
    rel: "dofollow",
    appearInTabs: true,
    appearInNav: false
  },
  brands: {
    path: "/brands",
    pathForNav: "/brands",
    pathName: "Brands",
    tKey: "paths.partners",
    rel: "nofollow",
    appearInNav: false
  },
  partners: {
    path: "/partners",
    pathForNav: "/partners",
    pathName: "Partners",
    tKey: "paths.partners",
    rel: "dofollow",
    appearInNav: true
  },
  business: {
    path: "/business",
    pathForNav: "/business",
    pathName: "Business",
    tKey: "paths.business",
    rel: "dofollow",
    appearInNav: true
  },
  pricing: {
    path: "/pricing",
    pathForNav: "/pricing",
    pathName: "Pricing",
    tKey: "paths.pricing",
    rel: "dofollow",
    appearInNav: true
  },
  nft: {
    path: "/nft",
    pathForNav: "/nft",
    pathName: "NFT",
    tKey: "paths.nft",
    rel: "nofollow",
    appearInNav: false
  },
  campaign: {
    path: "/campaign",
    pathForNav: "/campaign",
    pathName: "Campaign",
    tKey: "paths.campaign",
    rel: "nofollow",
    appearInNav: false
  },
  music: {
    appearInNav: true,
    path: "/music",
    pathForNav: "/music",
    pathName: "Music",
    rel: "dofollow"
  },
  gaming: {
    path: "/gaming",
    pathForNav: "/gaming",
    pathName: "Gaming",
    rel: "dofollow",
    appearInNav: true
  },
  // blog: {
  //   appearInNav: true,
  //   path: "/blog",
  //   pathForNav: "/blog",
  //   pathName: "Blog",
  //   rel: "dofollow"
  // },
  development: {
    path: "/development",
    pathForNav: "/development",
    pathName: "Development",
    rel: "nofollow",
    appearInNav: false
  },
  download: {
    path: "/download",
    pathForNav: "/download",
    pathName: "",
    rel: "",
    appearInNav: false
  },
  notFound: {
    path: "/*",
    pathForNav: "/*",
    pathName: "",
    rel: "nofollow",
    appearInNav: false
  }
};
const { development, ...rest } = paths;
const { development: dev, notFound: nf, lifestyle, fashion, beauty, ...withoutDevAndNotFound } = paths;
export const pathsWithoutDevAndNotFound = withoutDevAndNotFound;

export const pathsWithoutDev = Object.assign({}, rest);

export const pathObjectKeys = Object.keys(pathsWithoutDev);
const values = Object.values(pathsWithoutDev);
export const routerLinks = values.map((value) => value.path);
const { notFound, ...rest2 } = pathsWithoutDev;

const values2 = Object.values(rest2);
export const navLinkNames = values2.map((value) => value.pathName);

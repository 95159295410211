import useAsyncGetSessionStored from "@hooks/useAsyncGetSessionStored";
import go2nftAPI from "src/integrations/backend/go2nft.api";
import { SetDTO } from "src/integrations/backend/go2nft.dto";
import { EntertainmentCategories } from "../categories";

export interface ProductReturn {
  isLoading: boolean;
  value: SetDTO | null;
}

interface Args {
  id: string;
  category: EntertainmentCategories;
}

const PRODUCT_STORAGE_KEY = "product_key";

const useProduct = ({ id, category }: Args): ProductReturn => {
  const product = useAsyncGetSessionStored<SetDTO>(
    go2nftAPI.getSetByIdTHROWS as any,
    `${category}-${PRODUCT_STORAGE_KEY}-${id}`,
    id
  );

  return product;
};

export default useProduct;

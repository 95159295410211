import Navbar from "@pages/shared/Navbar/Navbar";
import Layout from "../Layout";
import { IChildren } from "@components/interfaces/IChildren";

const LayoutFreeContent = ({ children }: IChildren) => {
  return (
    <Layout variant={"navbar-free-content"} Navbar={Navbar}>
      {children}
    </Layout>
  );
};

export default LayoutFreeContent;

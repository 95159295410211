import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import layoutLocales from "@locales/en/layout.json";

import "./NavLink.scss";

export interface NavLinkProps {
  pathForNav: string;
  pathName: string;
  tKey?: `paths.${keyof typeof layoutLocales["paths"]}`;
  rel: string;
  active: boolean;
}

const NavLink = ({ pathForNav, pathName, tKey, active, rel }: NavLinkProps) => {
  const { t } = useTranslation("layout");
  const { t: commonT } = useTranslation("common");

  const classes = `nav-link${active ? " active" : ""}`;

  return (
    <Link to={pathForNav} className={classes} rel={rel} title={commonT("goToPage", { page: tKey || pathName })}>
      {t(tKey as any) || pathName}
    </Link>
  );
};

export default NavLink;
